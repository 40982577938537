<template>
  <Breadcrumb />
  <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="pills-view-filters-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-view-filters"
        type="button"
        role="tab"
        aria-selected="true"
      >
        {{
          $t(
            "ViewFilters.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-system-filters-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-system-filters"
        type="button"
        role="tab"
        aria-selected="false"
      >
        {{
          $t(
            "ViewFilters.SystemViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <div
      class="tab-pane fade active show"
      id="pills-view-filters"
      role="tabpanel"
    >
      <Grid
        ref="viewFilterGrid"
        :settings="gridSettings"
        :columns="gridColumns"
        @onEditButtonClick="onEditButtonClick"
      />
    </div>
    <div class="tab-pane fade" id="pills-system-filters" role="tabpanel">
      <Grid
        ref="systemViewFilterGrid"
        :settings="gridSystemViewFilterSettings"
        :columns="gridColumns"
        @onEditButtonClick="onEditButtonClick"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ViewFilterDesignList",
  data() {
    return {
      gridSettings: {
        action: "ViewFilterDesignList",
        requestUrl: String.format(
          "/Lcdp-ViewFilterListViewFilters?listTypeId=0"
        ),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,
        changeOrderUrl: "",

        buttons: [
          {
            name: "editViewFilter",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridSystemViewFilterSettings: {
        action: "DesignSystemViewFilters",
        requestUrl: String.format(
          "/Lcdp-ViewFilterListViewFilters?listTypeId=1"
        ),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,
        changeOrderUrl: "",
        isGatewayRequest: true,

        buttons: [
          {
            name: "editViewFilter",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "ViewFilters.CustomObjectName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "customObjectName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ViewFilters.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ViewFilters.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "ViewFilters.isDefault",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isDefaultFilter",
          type: "boolean",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onEditButtonClick(rowData) {
      let route = this.$router.resolve({
        name: "CustomObjectEditViewFilter",
        params: {
          customObjectId: rowData.customObjectPublicId,
          viewFilterId: rowData.publicId,
        },
      });
      window.open(route.href);
    },
  },
  mounted() {},
};
</script>
